import { createSlice } from "@reduxjs/toolkit";

const membershipSlice = createSlice({
  name: "membership",
  initialState: {
    newMembershipPlans: [],
    redirectMember: false,
    disableLogin: false,
    baladAppCounter: {},
  },
  reducers: {
    setNewMembershipPlans(state, action) {
      state.newMembershipPlans = action.payload;
    },
    setRedirectMember(state, action) {
      state.redirectMember = action.payload;
    },
    setDisableLogin(state, action) {
      state.disableLogin = action.payload;
    },
    setBaladAppCounter(state, action) {
      state.baladAppCounter = action.payload;
    }
  },
});

export const { setNewMembershipPlans, setRedirectMember, setDisableLogin, setBaladAppCounter } = membershipSlice.actions;

export default membershipSlice.reducer;
