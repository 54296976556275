import React, { useEffect, useState, useMemo, Suspense } from 'react';
import { Router, useHistory, useLocation } from 'react-router-dom';
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAnalytics } from 'firebase/analytics';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { onMessage } from "firebase/messaging";
import styled from '@emotion/styled';
import { useDispatch } from 'react-redux';

import HeaderV3, { HeaderSocial } from '../Header/HeaderV3.js';
import UserHelper from '../utils/UserHelper';
import DataHelper from '../utils/DataHelper';
import ToolHelper from '../utils/ToolHelper';
import MessageHelper from '../utils/MessageHelper';
import Loader from '../Molecules/Loader.js';
import Popup from '../Molecules/Popup.js';
import RightPanel from '../Molecules/RightPanel';
import NotificationContainer from '../Molecules/NotificationContainer.js';
import FloatingMessageContainer from '../Molecules/FloatingMessageContainer.js';
import DeclareRoutes from '../routes/DeclareRoutes.js';
import Seo from '../routes/Seo.js';
import ReactGA from 'react-ga4';
import GlobalPopup from '../GlobalPopup/index.js';
import { ConfigContext } from '../../context/ConfigContext.js';
import FloatingBottomBar from '../Molecules/FloatingBottomBar.js';
import { ScreenPropsContext } from '../../context/ScreenPropsContext.js';
import backgroundImage from '../../assets/bg-cru.jpg';
import { BREAKPOINTS } from '../configs/consts.js';
import { DeviceContext } from '../../context/DeviceContext.js';
import { setRecentNews } from '../../redux/news.js';
import { setUserData } from '../../redux/userData.js';
import  { setNewMembershipPlans, setRedirectMember, setDisableLogin, setBaladAppCounter } from '../../redux/membership.js';
import { setRecentBanners } from '../../redux/banners.js';
import { setNews, setHighlightedNews } from '../../redux/news.js';
import { setBenefitsToShow } from '../../redux/loyalty.js';
import { setAudienceTypes } from '../../redux/audienceTypes.js';
import { setSocialMedias } from '../../redux/socialMedias.js';
import Chatbot from '../Molecules/Chatbot.js';
import PrivacyCookiesPolicy from '../PrivacyCookiesPolicy/index.js';
import { useSelector } from 'react-redux';

const CoreLoader = (props) => {
  let { configFiles, match } = props;
  let app = initializeApp(configFiles.firebaseConfig);
  const dispatch = useDispatch();
 
  if(configFiles.firebaseConfig.trackingId || configFiles.firebaseConfig.measurementId){
    ReactGA.initialize(configFiles.firebaseConfig.trackingId || configFiles.firebaseConfig.measurementId);
  }

  const analytics = getAnalytics(app);
  let db = getFirestore(app);
  let userHelper = useMemo(() => new UserHelper(app,db,configFiles.getConfigFile),[]);
  let dataHelper = useMemo(() => new DataHelper(app,db,configFiles.getConfigFile),[]);
  userHelper.setDataHelper(dataHelper);
  dataHelper.setUserHelper(userHelper);

  const history = useHistory();
  const location = useLocation();
  const popup = ToolHelper.usePopup();
  const rightPanel = ToolHelper.useRightPanel();
  const notification = MessageHelper.useNotification();
  const message = MessageHelper.useMessage();

  const [cases,setCases] = useState([]);
  const [challenges,setChallenges] = useState([]);
  const [howToEarns,setHowToEarns] = useState([]);
  const [banners,setBanners] = useState([]);
  const [invoices,setInvoices] = useState([]);
  const [ytVideos,setYTVideos] = useState([]);
  const [unsubFunctions,setUnsubFunctions] = useState({});
  const [user,setUser] = useState({});
  const [isLoadedUser,setIsLoadedUser] = useState(false);
  const [hasAuthResponse,setHasAuthResponse] = useState(false);
  const [screenProps,setScreenProps] = useState({});
  const [isLoadedSnapshots,setIsLoadedSnapshots] = useState(false);
  const [doLoadSnapshots,setDoLoadSnapshots] = useState(false);
  const [showHeader,setShowHeader] = useState(false);
  const [showBlockPageModal, setShowBlockPageModal] = useState(false);
  const [authObserver,setAuthObserver] = useState(null);
  const [clientLocation,setClientLocation] = useState({city: 'São Paulo', region: 'State of São Paulo', region_code: 'SP'});
  const [strings,setStrings] = useState({});
  const [config, setConfig] = useState({
    appconfig: configFiles.APPCONFIG,
    layout: configFiles.LAYOUT,
    strings: strings, //configFiles.STRINGS,
    presets: configFiles.PRESETS,
    colors: configFiles.COLORS,
    theme: configFiles.THEME,
    logo: configFiles.logo,
    placeholder: configFiles.placeholder,
    Userform: configFiles.Userform,
    Caseform: configFiles.Caseform,
    firebaseConfig: configFiles.firebaseConfig
  });
  const [deviceType,setDeviceType] = useState('mobile');

  const { disableLogin } = useSelector((state) => state.membership);

  const getDeviceType = () => {
    let width = window.innerWidth;

    if(width <= BREAKPOINTS.TABLET_PORTRAIT){
      setDeviceType('mobile');
    }
    else{
      setDeviceType('desktop');
    }
  }

  useEffect(() => {
    getDeviceType();
    window.addEventListener('resize',getDeviceType);
    return () => window.removeEventListener('resize', getDeviceType);
  },[])

  // useEffect(() => {
  //   console.log('unsubFunctions',unsubFunctions)
  // },[unsubFunctions])

  const unsubscribe = () => {
    if(unsubFunctions && Object.keys(unsubFunctions).length > 0){
      let unsubArray = Object.entries(unsubFunctions);

      unsubArray.forEach((unsub) => {
        if(unsub[1] && typeof unsub[1] === 'function'){
          unsub[1]();
        }
      });

      // setDoLoadSnapshots(false);
      // setIsLoadedSnapshots(false);
      setUnsubFunctions({});
      setUser({});
    }

    navigator.serviceWorker.removeEventListener('message',windowMessageHandler)
  }

  const windowMessageHandler = (params) => {
    if(params?.data?.messageType === 'notification-clicked'){
      notification.add(params.data.notification,params.data.data)
    }
  }

  useEffect(() => {
    changeScreenProps();
  },[user,challenges,howToEarns,banners,ytVideos,config,showHeader,clientLocation])

  useEffect(() => {
    if(user?.Id){
      userHelper.setUserType(user);
    }
  },[user])

  useEffect(() => {
    loadStrings();
    loadThemeConfig();
  },[global.userType])

  useEffect(() => {
    if(user?.Id){
      // TODO - É necessário organizar esta chamada com outras chamadas que dependem do userType.
      // Além disso, estas chamadas precisam de unsubscribe e precisam respeitar isLoadedSnapshots e doLoadSnapshots
      // let ta = async () => {
      //   let tt = (await ToolHelper.importByUserType('db/news',configFiles.getConfigFile)).default;
      //   // console.log('snap by user type')
      //   dataHelper.snapConfig(tt,(list) => {
      //     setNews(list);
      //   })
      // }
      // ta();
      // ------------------------------------------
    }
  },[user.Id])

  useEffect(() => {
    setTimeout(() => {
      ReactGA?.send("pageview");
    },200)

    setHasAuthResponse(false);
    startAuthObserver();
    message.closeAllPageMessages();
  },[location.pathname])

  useEffect(() => {
    if(user?.Id || user?.UID){
      const route = ToolHelper.getRouteByLocation(location,configFiles.routesO);

      global.needsToValidateAccessCode = false;

      setHasAuthResponse(true);
    }
  },[user.Id,user.UID,location.pathname])
  // },[user,location.pathname])

  useEffect(() => {
    loadClientLocation();
    if(navigator?.serviceWorker){
      navigator.serviceWorker.addEventListener('message',windowMessageHandler);
  
      onMessage(userHelper.getMessaging(), (payload) => {
        notification.add(payload.notification,payload.data)
      });
  
      return () => unsubscribe();
    }
  },[])


  useEffect(() => {
    if (Object.keys(user).length > 0 && disableLogin) {
      unsubscribe();
      userHelper.logout(() => {
       return
      },(error) => {
        return
      })
    }

  },[disableLogin, user])

  // useEffect(() => {
  //   if(!isLoadedSnapshots && doLoadSnapshots){
  //     loadSnapshots();
  //   }
  // },[doLoadSnapshots, isLoadedSnapshots])

  const loadThemeConfig = async () => {
    const importedFile = await ToolHelper.importByUserType('layout/theme',configFiles.getConfigFile);
    setConfig(c => {c.theme = importedFile.default; return c;});
  }

  const loadStrings = async () => {
    const importedFile = await ToolHelper.importByUserType('basics/strings',configFiles.getConfigFile);
    setConfig(c => {c.strings = importedFile.default; return {...c}})
  }

  const loadUnloggedSnapshots = async () => {
    let unsubT = {};

    unsubT = { ...unsubT, ...dataHelper.snapNews((list) => {
      let payload = {
        news: list,
        team: 'ALL',
      }

      dispatch(setNews(payload));
    }, window.location.pathname)};

    unsubT = { ...unsubT, ...dataHelper.snapHighlightedNews((list) => {
      dispatch(setHighlightedNews(list));
    })};
/*
    unsubT = { ...unsubT, ...dataHelper.snapYTVideos((list) => {
      setYTVideos(list);
      console.log('loadSnapshots setYTVideos',list)
    })};
*/
    let unsubBanners = await dataHelper.snapBanners((list) => {
      dispatch(setRecentBanners({banners: list}));
      setBanners(list);
    })

    unsubT = { ...unsubT, ...unsubBanners};

    let unsubSocialMedias = await dataHelper.snapSocialMedias((socialMedia) => {
      dispatch(setSocialMedias({socialMedias: socialMedia}));
    });

    unsubT = { ...unsubT, ...unsubSocialMedias};

    let redirectMemberData = await dataHelper.getRedirectMember((redirect) => {
      dispatch(setRedirectMember(redirect));
    });

    unsubT = { ...unsubT, ...redirectMemberData};

    let newMembershipPlans = await dataHelper.getNewMembershipPlans((membership) => {
      dispatch(setNewMembershipPlans(membership));
    });

    unsubT = { ...unsubT, ...newMembershipPlans};

    let disableLogin = await dataHelper.disableLogin((isDisabled) => {
      dispatch(setDisableLogin(isDisabled));
    });

    unsubT = { ...unsubT, ...disableLogin};

    let benefitsToShow = dataHelper.getBenefitsToShow((benefits) => {
      dispatch(setBenefitsToShow(benefits));
    });

    unsubT = { ...unsubT, ...benefitsToShow};

    unsubT = { ...unsubT, ...await dataHelper.snapAudienceTypes((list) => {
      dispatch(setAudienceTypes(list));
    })};

    unsubT = { ...unsubT, ...await dataHelper.getBaladAppSocioCounter((baladAppCounter) => {
      dispatch(setBaladAppCounter(baladAppCounter));
    })};
    
    setUnsubFunctions(us => ({ ...us, ...unsubT }));
  }

  const startAuthObserver = async () => {
    if(!authObserver){
      const authState = onAuthStateChanged(getAuth(),async () => {
        let userRef = await userHelper.getUserFirebaseId();
        loadUnloggedSnapshots();
        setHasAuthResponse(true);

        if(userRef) {
          global.isAuthenticated = true;
          await userHelper.setLastTimeOnline();

          await loadSnapshots();
          // if(!isLoadedSnapshots){
          //   console.log('is not loaded snapshots')
          //   setDoLoadSnapshots(true);
          // }
          // else{
          //   console.log('is loaded snapshots')
          // }
        }
        else{
          global.isAuthenticated = false;
        }
      })
      setAuthObserver(authState);
      setUnsubFunctions(us => { us['authState' + new Date().getTime()] = authState; return us; });
    }
  }

  const loadSnapshots = async () => {
    let unsubT = {};

    unsubT = { ...unsubT, ...await userHelper.snapUser((data) => {
      setUser(data);
      dispatch(setUserData(data));
    })};
    
    // setIsLoadedSnapshots(true);
    // setDoLoadSnapshots(false);
    setUnsubFunctions(us => ({ ...us, ...unsubT }));
  }

  const openGlobalPopup = (popupName) => {
    GlobalPopup(screenProps,popupName);
  }

  const changeScreenProps = () => {
    setScreenProps({
      match,
      math: Math.random(),
      setShowHeader,
      userHelper,
      dataHelper,
      history,
      unsubscribe,
      routes: configFiles.routesO,
      data: {
        user,
        cases,
        howToEarns,
        banners,
        ytVideos,
        showHeader,
        invoices,
        clientLocation,
      },
      config,
      popup,
      openGlobalPopup,
      rightPanel,
      notification,
      message,
      getConfigFile: configFiles.getConfigFile,
    })
  }

  const loadClientLocation = async () => {
    const ip = await userHelper.getClientIP();
    const key = "gmYKPNdWqKq0sowz"

    if (ip) {
      fetch(`https://ipwhois.pro/${ip}?key=${key}&output=json`)
        .then((res) => res.json())
        .then(({ city, region, region_code }) => {
          setClientLocation({ city, region, region_code });
        })
    }
  }

  return <>
    <Seo location={location} configFiles={configFiles} />
    <ScreenPropsContext.Provider value={screenProps}>
    <ConfigContext.Provider value={config}>
    <DeviceContext.Provider value={deviceType}>
      {!hasAuthResponse ? 
        <Loader/> :
        <Router history={history}>
          <Suspense fallback={<Loader/>}>
              <StyledCoreLoader id={'core-loader-main'} config={config} bg={backgroundImage}>
                <div className='starred-bg' />
                <HeaderSocial {...screenProps}/>
                <HeaderV3 {...screenProps}/>
                <DeclareRoutes screenProps={screenProps} routes={configFiles.routes} routesO={configFiles.routesO}/>
                <Popup control={popup}/>
                <RightPanel control={rightPanel} />
                <NotificationContainer control={notification} config={config} />
                <FloatingMessageContainer control={message} config={config} />
                <FloatingBottomBar />
                {/* <Chatbot /> */}
                <PrivacyCookiesPolicy {...screenProps} />
              </StyledCoreLoader>
          </Suspense>
        </Router>
      }
    </DeviceContext.Provider>
    </ConfigContext.Provider>
    </ScreenPropsContext.Provider>
  </>
}

export default CoreLoader;

const StyledCoreLoader = styled.div`
  width: 100vw;
  display: flex;
  flex-direction: column;
  font-family: ${props => props.config?.layout?.FONT_FAMILY};
  font-size: ${props => props.config?.layout?.FONT_BASE_SIZE};
  background-color: ${props => props.config?.colors?.BRAND.DARK20};

  .starred-bg{
    position: absolute;
    width: 100vw;
    height: 300px;
    top: 0;
    left: 0;
    z-index: -1;
    background-image: url(${props => props.transparent ? 'transparent' : props.bg});
    background-position: top;
    background-repeat: repeat-x;
  }
`