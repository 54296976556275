import { useState, useMemo, useEffect, useContext, useRef } from "react";
import { withRouter } from "react-router";
import {
  FaFacebook,
  FaInstagram,
  FaMagnifyingGlass,
  FaTiktok,
  FaTriangleExclamation,
  FaUser,
  FaXTwitter,
  FaYoutube,
} from "react-icons/fa6";
import { IoIosMenu } from "react-icons/io";
import ToolHelper from "../utils/ToolHelper";
import { useSelector } from "react-redux";
import styled from "@emotion/styled";
import { BREAKPOINTS } from "../configs/consts";
import CoreLink from "../Molecules/CoreLink.js";
import logo from "../../assets/cruzeiro.png";
import ticket from "../../assets/ticket.png";
import bag from "../../assets/bag.png";

import Button from "../Atoms/Button.js";
import avatar from "../../assets/avatar.png";
import Image from "../Molecules/Image.js";
import Txt from "../Atoms/Txt.js";
import { TypographyContext } from "../../context/TypographyContext.js";
import Link from "../Atoms/Link.js";
import Avatar from "../Atoms/Avatar.js";
import { ScreenPropsContext } from "../../context/ScreenPropsContext.js";
import HamIcon from "../Molecules/HamIcon.js";
import HeaderDropMenu from "./HeaderDropMenu.js";
import SocioButton from "../Atoms/SocioButton.js";
import audienceHelper from "../utils/AudienceHelper";
import CruzeiroApi from "../utils/CruzeiroApi";

import { setRecentMenuItems } from "../../redux/menuItems.js";
import { DeviceContext } from "../../context/DeviceContext.js";

function HeaderV3Base(props) {
  const limitLoyaltyItems = 2;
  const thisRef = useRef(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [hasBgColor, setHasBgColor] = useState(false);
  const [headerConfig, setHeaderConfig] = useState({});
  const [loyaltyItems, setLoyaltyItems] = useState([]);
  const [adjustMenuPosition, setAdjustMenuPosition] = useState(false);
  const [isDesktop, setIsDesktop] = useState(false);
  const screenProps = useContext(ScreenPropsContext);
  const device = useContext(DeviceContext);
  const { dataHelper, userHelper, data, routes } = screenProps;
  const { user } = data;

  const { audienceTypes } = useSelector((state) => state.audienceTypes);
  const { userData } = useSelector((state) => state.userData);
  const { disableLogin } = useSelector((state) => state.membership);
  const { benefitsToShow } = useSelector((state) => state.loyalty);

  const { getConfigFile } = props;

  // const categories = [
  //   { label: "masculino", hide: false },
  //   { label: "feminino", hide: false },
  //   { label: "base", hide: true },
  // ];
  
  const config = useMemo(
    () => ToolHelper.getThemedConfig(props, "Header.preset"),
    [props]
  );

  const generateMainLink = (linkData) => {
    return {
      label: linkData?.Name,
      url: "",
      target: "_self",
      redirectScreen: linkData?.RedirectScreen__c,
      redirectId: linkData?.RedirectId__c,
      action: linkData?.Action__c,
      targetGroup: linkData?.TargetGroup__c,
      showItems: false,
      id: linkData?.Id,
      showContent: linkData?.showContent,
      order: linkData?.Order__c,
      items: [],
    };
  };

  const cleanPath = (path) => {
    let secondBarPosition = path?.indexOf("/", path?.indexOf("/") + 1);

    if (secondBarPosition !== -1) {
      path = path?.substring(0, secondBarPosition);
    }

    return path;
  };

  const generateGeneralMainMenuUrl = (linkData) => {
    let redirectUrl = "";
    if (linkData?.RedirectScreen__c && linkData?.Action__c === "Redirect") {
      if (linkData?.RedirectScreen__c === "ArticleScreen") {
        redirectUrl = cleanPath(routes.content.path);
      } else if (linkData?.RedirectScreen__c === "HomeScreen") {
        redirectUrl = routes.home.path;
      } else if (linkData?.RedirectScreen__c === "NewsScreen") {
        redirectUrl = routes.news.path;
      }

      if (linkData?.RedirectId__c) {
        redirectUrl += "/" + linkData?.RedirectId__c;
      }
    }

    if (
      !linkData?.RedirectScreen__c &&
      linkData?.Action__c &&
      linkData?.RedirectId__c
    ) {
      redirectUrl += "/" + linkData?.RedirectId__c;
    }

    if (linkData?.Action__c.includes("Swap")) {
      if (linkData?.Action__c === "Swap MASC") {
        redirectUrl = routes.home.path;
      }

      if (linkData?.Action__c === "Swap FEM") {
        redirectUrl = routes.home.path;
      }

      if (linkData?.Action__c === "Swap BASE") {
        redirectUrl = routes.home.path;
      }
    }

    /*if(redirectUrl === '') {
      redirectUrl = routes.home.path;
    }*/

    return redirectUrl;
  };

  const generateSubMenuUrl = (subLinkData) => {
    let url = "";
    let target = "_self";

    if (!subLinkData?.Url__c) {
      url =
        cleanPath(routes?.content?.path) +
        "/" +
        subLinkData?.Article__r?.FirebaseId__c;
    }

    if (subLinkData?.Url__c) {
      if (
        subLinkData?.Url__c.includes("http") ||
        subLinkData?.Url__c.includes("www")
      ) {
        url = subLinkData?.Url__c;
        target = "_blank";
      } else {
        url = subLinkData?.Url__c;
      }
    }

    return {
      label: subLinkData?.Name,
      target: target,
      url: url,
      id: subLinkData?.Id,
    };
  };

  const prepareData = (list) => {
    list = audienceHelper.verifyCanShowContentList(list, audienceTypes);

    let links = [];

    for (let linkItem of list) {
      if (linkItem?.Action__c?.includes("Swap")) {
        continue;
      }

      let link = generateMainLink(linkItem);
      link.url = generateGeneralMainMenuUrl(linkItem);
      link.showContent = true;

      let subLinks = [];
      if (linkItem?.MenuSubItems__r) {
        for (let subLinkData of linkItem?.MenuSubItems__r?.records) {
          subLinks.push(generateSubMenuUrl(subLinkData));
        }
      }

      link.items = subLinks;
      links.push(link);
    }

    links.sort((linkA, linkB) => {
      if (linkA.order < linkB.order) {
        return -1;
      }
      if (linkA.order > linkB.order) {
        return 1;
      }
      return 0;
    });

    return {
      links: links,
    };
  };

  const handleOpenSubMenus = (menuId) => {
    for (let menu of headerConfig?.links) {
      if (menu.id === menuId) {
        menu.showItems = !menu.showItems;
        break;
      }
    }

    setHeaderConfig({ ...headerConfig });
  };

  useEffect(() => {
    const unsub = dataHelper.snapMenuItems((list) => {
      setHeaderConfig(prepareData(list));
    });

    //return () => unsub()
  }, []);

  useEffect(() => {
    loadLoyaltyItems();
  }, [global.userType, user, userData]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, true);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    if (window.scrollY > 50) {
      setHasBgColor(true);
      setAdjustMenuPosition(true);
    } else {
      setHasBgColor(false);
      setAdjustMenuPosition(false);
    }
  };

  const loadLoyaltyItems = async () => {
    let items = await userHelper.getLoyaltyItems(limitLoyaltyItems);
    let itemsToShow = items?.filter((item) => benefitsToShow?.includes(item?.Type__c));
    setLoyaltyItems(itemsToShow);
  };

  const toggleMenu = (showMenu) => {
    if (showMenu !== undefined && showMenu !== false && showMenu !== true) {
      setIsMenuOpen((n) => !n);
      return;
    }

    setIsMenuOpen(showMenu);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > BREAKPOINTS?.TABLET_LANDSCAPE);
    };

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <StyledHeader
      hasBgColor={hasBgColor}
      headerConfig={headerConfig}
      showHeader={props.data.showHeader}
      config={config}
      data={props.data}
    >
      <div className="header-line lower-header">
        <div className="before-logo">
          <div className="menu-icon">
            <div onClick={toggleMenu}>
              <HamIcon
                size={30}
                className={isMenuOpen ? "open" : ""}
                color={config?.colors?.WHITE.W}
              />
            </div>
          </div>
          {/* <div className="left-links">
            {isDesktop && categories?.length > 0 && categories.map(
              (category, key) =>
                !category?.hide && (
                  <TypographyContext.Provider value="subtitle-lg" key={key}>
                    <Link href={`/categoria/${category?.label}`}>
                      {category?.label}
                    </Link>
                  </TypographyContext.Provider>
                )
            )}
          </div> */}
        </div>
        <div className="logo-container">
          <a href={routes.homeDeslogada.path}>
            <Image alt="logo" src={logo} />
          </a>
        </div>
        <div className="after-logo">
          <div className="right-links">
            <SocioTags loyaltyItems={loyaltyItems} />
          </div>
          {!disableLogin && (
            <div>
              <Avatar size={device === "mobile" ? 32 : 48} />
            </div>
          )}
        </div>
      </div>
      <div className="mobile-line">
        <SocioTags loyaltyItems={loyaltyItems} />
      </div>
      <HeaderDropMenu
        parentRef={thisRef}
        toggleIsOpen={toggleMenu}
        isOpen={isMenuOpen}
        config={config}
        menuItems={headerConfig?.links}
        handleOpenSubMenus={handleOpenSubMenus}
        loyaltyItems={loyaltyItems}
        data={data}
        adjustMenuPosition={adjustMenuPosition}
        // mobileItems={headerConfig?.headerUser?.menuItems}
        // rightPanel={rightPanel}
        // data={data}
        // dataHelper={dataHelper}
      />
    </StyledHeader>
  );
}

const HeaderV3 = withRouter(HeaderV3Base);

export default HeaderV3;

const SocioTags = (props) => {
  const { loyaltyItems } = props;
  const screenProps = useContext(ScreenPropsContext);
  const { config, routes, dataHelper, userHelper, history } = screenProps;
  const [loading, setLoading] = useState(false);
  const { disableLogin } = useSelector((state) => state.membership);
  
  const handleIngressoButton = async () => {
    const newWindow = window.open('about:blank', '_blank');
    newWindow.location.href = "https://acesso.cruzeiro.com.br/";

    return 
    // const user = await userHelper.getUser();
    // if (!disableLogin && (!user || Object.keys(user).length === 0)) {
    //   history.push(routes.login.path, {
    //     redirectUrl: routes.socioSSO.path,
    //   });
    //   return;
    // }
    // const newWindow = window.open('about:blank', '_blank');

    // try {
    //   if (loading) return;

    //   setLoading(true);
    //   const { success, login_url } = await CruzeiroApi.loginTicketSSO(
    //     user?.FutebolCardCPF__c,
    //     user?.FutebolCardEmail__c,
    //     dataHelper
    //   );

    //   if (success) {
    //     return newWindow.location.href = login_url;
    //   }
    //   newWindow.location.href = "https://cruzeiro.futebolcard.com";
    // } catch (error) {
    //   newWindow.location.href = "https://cruzeiro.futebolcard.com";
    // } finally {
    //   setLoading(false);
    // }
  };

  const handleRedirectLink = async (label, description) => {
    if (
      label?.toLowerCase()?.includes("ingresso") ||
      description?.toLowerCase()?.includes("ingresso")
    ) {
      await handleIngressoButton();
    } else if (
      label?.toLowerCase()?.includes("loja") ||
      description?.toLowerCase()?.includes("loja")
    ) {
      window.open("https://loja.cruzeiro.com.br/", "_blank");
    }
  };

  return (
    <StyledSocioTags>
      <TypographyContext.Provider value="subtitle-lg">
        {loyaltyItems?.length ? (
          loyaltyItems.slice(0, 2).map((item) => (
            <div
              onClick={() =>
                handleRedirectLink(item?.Label__c, item?.ShortDescription__c)
              }
              key={item?.Id}
              className={`loyalty-item ${
                item?.Label__c?.toLowerCase()?.includes("ingresso") ||
                item?.ShortDescription__c?.toLowerCase()?.includes(
                  "ingresso"
                ) ||
                item?.Label__c?.toLowerCase()?.includes("loja") ||
                item?.ShortDescription__c?.toLowerCase()?.includes("loja")
                  ? "click"
                  : ""
              }`}
            >
              <Image src={item?.ImageURL__c} />
              <Txt typo="subtitle-lg">{item?.Label__c}</Txt>
              <Txt
                typo="subtitle-lg"
                className="short-description"
                color={config?.colors?.BRAND.GOLD}
              >
                {item?.ShortDescription__c}
              </Txt>
            </div>
          ))
        ) : (
          <>
            <Button
              className="loyalty-item"
              onClick={handleIngressoButton}
              target="__blank"
            >
              <Image src={ticket} />
              <Txt typo="subtitle-lg">Ingressos</Txt>
            </Button>
            <Button
              className="loyalty-item"
              href="https://loja.cruzeiro.com.br/"
              target="__blank"
            >
              <Image src={bag} />
              <Txt typo="subtitle-lg">Loja</Txt>
            </Button>
          </>
        )}
        <SocioButton />
      </TypographyContext.Provider>
    </StyledSocioTags>
  );
};

export const HeaderSocial = (props) => {
  const config = useMemo(
    () => ToolHelper.getThemedConfig(props, "Header.preset"),
    [props]
  );
  const screenProps = useContext(ScreenPropsContext);
  const device = useContext(DeviceContext);
  const { dataHelper } = screenProps;
  const [headerMessage, setHeaderMessage] = useState("");
  const [showHeaderMessage, setShowHeaderMessage] = useState(false);
  const { socialMedias } = useSelector((state) => state.socialMedias);
  const socialMediasF = useMemo(() => socialMedias["Masc"], [socialMedias]);

  const { baladAppCounter } = useSelector((state) => state.membership);
  const { userData } = useSelector((state) => state.userData);

  const getHeaderMessage = async () => {
    let messageData = await dataHelper.getHeaderMessage();
   
    if (
      (messageData?.AudienceType__c === "Custom" &&
        userData[messageData?.CustomField__c] === messageData?.Value__c) ||
      messageData?.AudienceType__c === "All"
    ) {
      setHeaderMessage(messageData);
    } else {
      setHeaderMessage("");
    }
  };

  const socioNumber = useMemo(() => baladAppCounter?.counter?.toLocaleString('pt-BR'), [baladAppCounter])

  useEffect(() => {
    getHeaderMessage();
  }, [userData]);

  useEffect(() => {
    if (device === "mobile" && headerMessage && socioNumber) {
      const interval = setInterval(() => {
        setShowHeaderMessage((prevShowHeaderMessage) => !prevShowHeaderMessage);
      }, 3000);

      return () => clearInterval(interval);
    }
  }, [device, headerMessage, socioNumber]);

  const renderMobileView = () => (
    <>
      {headerMessage && (showHeaderMessage || !socioNumber) ? (
        <>
          {/* <FaTriangleExclamation color={props.config?.colors?.BRAND.GOLD} /> */}
          <a
            href={headerMessage.RedirectLink__c}
            target="_blank"
            rel="noreferrer"
          >
            <Txt typo="paragraph-lg" color={props.config?.colors?.BRAND.GOLD}>
              {headerMessage.ButtonText__c}
            </Txt>
          </a>
        </>
      ) : socioNumber ? (
        <Txt typo="subtitle-lg" color={props.config?.colors?.WHITE.W}>
          JÁ SOMOS <Txt typo="heading-sm">{socioNumber}</Txt>{" "}
          <Txt typo="subtitle-lg">SÓCIOS</Txt>
        </Txt>
      ) : null}
    </>
  );

  const renderDeskopView = () => (
    <>
      {socioNumber && (
        <Txt typo="subtitle-lg" color={props.config?.colors?.WHITE.W}>
          JÁ SOMOS <Txt typo="heading-sm">{socioNumber}</Txt>{" "}
          <Txt typo="subtitle-lg">SÓCIOS</Txt>
        </Txt>
      )}
      {headerMessage && (
        <>
          {/* <FaTriangleExclamation color={props.config?.colors?.BRAND.GOLD} /> */}
          <a href={headerMessage.RedirectLink__c} target="_blank" rel="noreferrer">
            <Txt typo="paragraph-lg" color={props.config?.colors?.BRAND.GOLD}>
              {headerMessage.ButtonText__c}
            </Txt>
          </a>
        </>
      )}
    </>
  );

  return (
    <StyledHeaderSocial
      config={config}
      showHeader={props.data.showHeader}
      device={device}
    >
      <div className="header-line">
        <div className="warning">
          {device === "mobile" ? renderMobileView() : renderDeskopView()}
        </div>
        <div className="socials">
          {device !== "mobile" &&
            socialMediasF?.map((social, key) => (
              <a
                key={key}
                href={social.Url__c}
                target={"_blank"}
                rel="noreferrer"
              >
                <Image src={social.Image__c} width={24} />
              </a>
            ))}
        </div>
      </div>
      <div className="header-divider" />
    </StyledHeaderSocial>
  );
};

/* style */

const StyledSocioTags = styled.div`
  display: flex;
  flex-direction: row;
  gap: 32px;

  .click {
    cursor: pointer;
  }

  .loyalty-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 5px;

    &.click {
      cursor: pointer;
      &:hover {
        opacity: 0.8;
      }
    }

    .short-description {
      @media (max-width: ${BREAKPOINTS.PHONE_BIGGER}px) {
        display: none;
      }
    }

    img {
      height: 24px;
      margin-right: 8px;
    }
  }
`;

const StyledHeader = styled.div`
  display: ${(props) => (props.showHeader ? "flex" : "none")};
  position: sticky;
  flex-direction: column;
  top: 0;
  z-index: 999;
  color: #ffffff;
  justify-content: center;
  align-items: center;
  background-color: ${(props) =>
    !props.hasBgColor ? "transparent" : "rgba(00,03,54,0.7)"};
  /* backdrop-filter: blur(4px); */

  a {
    display: flex;
    align-items: center;
    gap: 12px;
    color: #ffffff;
    text-transform: uppercase;

    &:hover:not(.active) {
      opacity: 0.48;
    }

    .active {
      color: ${(props) => props.config?.colors.BRAND.GOLD};
    }
  }

  .header-line {
    display: flex;
    flex-direction: row;
    align-self: center;
    justify-content: space-between;
    max-width: ${(props) =>
      props.config?.layout?.CONTAINER_SIZE + "px" || "none"};
    width: calc(100% - 64px);
    margin: auto;
  }

  .lower-header {
    padding: 20px 0;
    position: sticky;

    .menu-icon {
      margin-left: -4px;
    }

    .left-links {
      display: flex;
      margin-left: 94px;
      gap: 53px;
    }

    > div {
      display: flex;
      align-self: center;

      > div {
        display: flex;
        align-self: center;
      }
    }

    .logo-container {
      position: absolute;
      left: 50%;
      transform: translate(-50%);

      img {
        height: 62px;

        @media (max-width: ${BREAKPOINTS.PHONE_BIGGER}px) {
          height: 40px;
        }
      }
    }

    .after-logo {
      gap: 24px;

      .right-links {
        gap: 48px;

        @media (max-width: ${BREAKPOINTS.TABLET_LANDSCAPE}px) {
          display: none;
        }
      }
    }
  }

  .mobile-line {
    padding-bottom: 20px;
    display: none;

    @media (max-width: ${BREAKPOINTS.TABLET_LANDSCAPE}px) {
      display: block;
      height: 60px;
    }
  }

  .links-line {
    flex: 1;
    flex-direction: row;
    align-self: center;
    justify-content: space-between;
    display: flex;
    padding-left: 20px;
    padding-right: 20px;
    /* padding: 0 110px; */

    @media (max-width: ${BREAKPOINTS.TABLET_PORTRAIT}px) {
      display: none;
    }
  }
`;

const StyledHeaderSocial = styled.div`
  display: ${(props) => (props.showHeader ? "flex" : "none")};
  flex-direction: column;
  top: 0;
  z-index: 999;
  color: #ffffff;
  justify-content: center;
  align-items: center;
  background-color: ${(props) =>
    !props.hasBgColor ? "transparent" : "rgba(00,03,54,0.7)"};

  .header-line {
    display: flex;
    flex-direction: row;
    align-self: center;
    justify-content: space-between;
    max-width: ${(props) =>
      props.config?.layout?.CONTAINER_SIZE + "px" || "none"};
    width: calc(100% - 64px);
    margin: auto;
    min-height: 44px;
  }

  .header-divider {
    width: 100%;
    height: 1px;
    background-color: ${(props) => props.config?.colors?.WHITE.W32};
  }

  .warning {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 24px;
    align-self: center;

    span {
      display: flex;
      align-items: baseline;
    }
  }

  .socials {
    padding: 10px 0;
    display: flex;
    gap: 32px;

    a {
      display: flex;
      align-items: center;
      gap: 12px;
      color: #ffffff;
      text-transform: uppercase;
      letter-spacing: 0.08em;

      &:hover:not(.active) {
        opacity: 0.48;
      }

      .active {
        color: ${(props) => props.config?.colors.BRAND.GOLD};
      }
    }
  }
`;
