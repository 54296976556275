import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from '@emotion/styled';
import Txt from "../Atoms/Txt";
import { X} from "react-feather";
import { FaWhatsapp } from "react-icons/fa";
import { FaRegEdit } from "react-icons/fa";
import { LuPhone } from "react-icons/lu";
import { SlEarphonesAlt } from "react-icons/sl";
import { FaAngleRight, FaRegEnvelope } from "react-icons/fa6";
import ReactGA from 'react-ga4';

import { BREAKPOINTS } from "../configs/consts";
import Link from "../Atoms/Link";
import audienceHelper from "../utils/AudienceHelper";


const Footer = (props) => {
    const { config, routes, dataHelper } = props;

    const [showSupport, setShowSupport] = useState(false);
    const [sponsors, setSponsors] = useState([]);
    const [sponsorLevel1, setSponsorLevel1] = useState([]);
    const [sponsorLevel2, setSponsorLevel2] = useState([]);
    const [sponsorLevel3, setSponsorLevel3] = useState([]);
    const [links, setLinks] = useState([]);

    const { audienceTypes } = useSelector((state) => state.audienceTypes);

    const cleanPath = (path) => {
      let secondBarPosition = path?.indexOf("/", path?.indexOf("/") + 1);
  
      if (secondBarPosition !== -1) {
        path = path?.substring(0, secondBarPosition);
      }
  
      return path;
    };

    const generateMainLink = (linkData) => {
      return {
        label: linkData?.Name,
        url: "",
        target: "_self",
        redirectScreen: linkData?.RedirectScreen__c,
        redirectId: linkData?.RedirectId__c,
        action: linkData?.Action__c,
        targetGroup: linkData?.TargetGroup__c,
        showItems: false,
        id: linkData?.Id,
        showContent: linkData?.showContent,
        order: linkData?.Order__c,
        items: [],
      };
    };

    const generateGeneralMainMenuUrl = (linkData) => {
      let redirectUrl = "";
      if (linkData?.RedirectScreen__c && linkData?.Action__c === "Redirect") {
        if (linkData?.RedirectScreen__c === "ArticleScreen") {
          redirectUrl = cleanPath(routes.content.path);
        } else if (linkData?.RedirectScreen__c === "HomeScreen") {
          redirectUrl = routes.home.path;
        } else if (linkData?.RedirectScreen__c === "NewsScreen") {
          redirectUrl = routes.news.path;
        }
  
        if (linkData?.RedirectId__c) {
          redirectUrl += "/" + linkData?.RedirectId__c;
        }
      }
  
      if (
        !linkData?.RedirectScreen__c &&
        linkData?.Action__c &&
        linkData?.RedirectId__c
      ) {
        redirectUrl += "/" + linkData?.RedirectId__c;
      }
  
      if (linkData?.Action__c.includes("Swap")) {
        if (linkData?.Action__c === "Swap MASC") {
          redirectUrl = routes.home.path;
        }
  
        if (linkData?.Action__c === "Swap FEM") {
          redirectUrl = routes.home.path;
        }
  
        if (linkData?.Action__c === "Swap BASE") {
          redirectUrl = routes.home.path;
        }
      }
  
      /*if(redirectUrl === '') {
        redirectUrl = routes.home.path;
      }*/
  
      return redirectUrl;
    };

    const generateSubMenuUrl = (subLinkData) => {
      let url = "";
      let target = "_self";
  
      if (!subLinkData?.Url__c) {
        url =
          cleanPath(routes?.content?.path) +
          "/" +
          subLinkData?.Article__r?.FirebaseId__c;
      }
  
      if (subLinkData?.Url__c) {
        if (
          subLinkData?.Url__c.includes("http") ||
          subLinkData?.Url__c.includes("www")
        ) {
          url = subLinkData?.Url__c;
          target = "_blank";
        } else {
          url = subLinkData?.Url__c;
        }
      }
  
      return {
        label: subLinkData?.Name,
        target: target,
        url: url,
        id: subLinkData?.Id,
      };
    };

    const prepareData = (list) => {
      list = audienceHelper.verifyCanShowContentList(list, audienceTypes);
  
      let links = [];
  
      for (let linkItem of list) {
        if (linkItem?.Action__c?.includes("Swap")) {
          continue;
        }
  
        let link = generateMainLink(linkItem);
        link.url = generateGeneralMainMenuUrl(linkItem);
        link.showContent = true;
  
        let subLinks = [];
        if (linkItem?.MenuSubItems__r) {
          for (let subLinkData of linkItem?.MenuSubItems__r?.records) {
            subLinks.push(generateSubMenuUrl(subLinkData));
          }
        }
  
        link.items = subLinks;
        links.push(link);
      }
  
      links.sort((linkA, linkB) => {
        if (linkA.order < linkB.order) {
          return -1;
        }
        if (linkA.order > linkB.order) {
          return 1;
        }
        return 0;
      });
  
      return links
    };

    const loadGroupBanners = async () => {
      sponsors.forEach((sponsor) => {
        if (sponsor?.Location__c === "Sponsors Row 1") {
          setSponsorLevel1(sponsor?.AdvertisingItems__r?.records);
        }
        if (sponsor?.Location__c === "Sponsors Row 2") {
          setSponsorLevel2(sponsor?.AdvertisingItems__r?.records);
        }
        if (sponsor?.Location__c === "Sponsors Row 3") {
          setSponsorLevel3(sponsor?.AdvertisingItems__r?.records);
        }
      });
    };

    const handleAnalytics = (label, row) => {
      ReactGA?.event({
        category: `Sponsor Footer ${row}`,
        action: "Click",
        label,
      });
    };

    useEffect(() => {
      const loadBanners = async () => {
        const unsub = await dataHelper.snapBanners((banners) => {
          setSponsors(banners);
        });
        return async () => {
          unsub();
        };
      };
      loadBanners();
    }, [dataHelper]);
    
    useEffect(() => {
      loadGroupBanners();
    }, [sponsors]);

    useEffect(() => {
      const loadLinks = async () => {
        const unsub = await dataHelper.snapMenuItems((list) => {
          setLinks(prepareData(list));
        });

        return () => unsub();
      };
      loadLinks();
    }, []);

    return (
      <FooterContainer config={config}>
        {/* <LogoFooterContainer>
                <img src={logo} alt="Logo" />
            </LogoFooterContainer> */}
        <AllFootterContentContainer {...props}>
          <SponsorsContainer>
            {sponsorLevel1?.length > 0 && (
              <SponsorsContentContainer
                opacity={0.7}
                twoRows={true}
                hasBorderBottom={
                  sponsorLevel2?.length > 0 || sponsorLevel3?.length > 0
                }
              >
                {sponsorLevel1.map((sponsor, index) => (
                  <Link
                    key={index}
                    href={sponsor?.LinkURL__c}
                    target="_blank"
                    onClick={() => handleAnalytics(sponsor?.Name, 'row 1')}
                  >
                    <img src={sponsor?.ImageURL__c} alt={sponsor?.Name} />
                  </Link>
                ))}
              </SponsorsContentContainer>
            )}
            {sponsorLevel2?.length > 0 && (
              <SponsorsContentContainer
                opacity={0.7}
                twoRows={true}
                hasBorderBottom={sponsorLevel3?.length > 0}
              >
                {sponsorLevel2.map((sponsor, index) => (
                  <Link
                    key={index}
                    href={sponsor?.LinkURL__c}
                    target="_blank"
                    onClick={() => handleAnalytics(sponsor?.Name, 'row 2')}
                  >
                    <img src={sponsor?.ImageURL__c} alt={sponsor?.Name} />
                  </Link>
                ))}
              </SponsorsContentContainer>
            )}
            {sponsorLevel3?.length > 0 && (
              <SponsorsContentContainer
                gap={"46px"}
                width={"550px"}
                opacity={0.7}
                hasBorderBottom={false}
                twoRows={true}
              >
                {sponsorLevel3.map((sponsor, index) => (
                  <Link
                    key={index}
                    href={sponsor?.LinkURL__c}
                    target="_blank"
                    onClick={() => handleAnalytics(sponsor?.Name, 'row 3')}
                  >
                    <img src={sponsor?.ImageURL__c} alt={sponsor?.Name} />
                  </Link>
                ))}
              </SponsorsContentContainer>
            )}
          </SponsorsContainer>

          {links?.length > 0 && (
            <GridContainer>
              {links
                .filter((link) => link?.items?.length > 0)
                .map((link, index) => (
                  <LinksFooter
                    key={index}
                    title={link?.label}
                    items={link?.items}
                  />
                ))}
            </GridContainer>
          )}
          <div className="copyrights-container">
            <Txt typo="caption-lg-footer">
              © 2024 Cruzeiro SAF. Todos os direitos reservados.
            </Txt>
          </div>
        </AllFootterContentContainer>
        {showSupport && (
          <SupportModalOptionsContainer
            config={config}
            routes={routes}
            setShowSupport={setShowSupport}
          />
        )}
        <SupportMenuBottom config={config} setShowSupport={setShowSupport} />
        <SupportMenuMobileBottom
          config={config}
          setShowSupport={setShowSupport}
          showSupport={showSupport}
        />
      </FooterContainer>
    );
}


export default Footer;




const SupportMenuContainer = styled.div`
    /* Frame 1801 */
    /* Auto layout */
    display: flex;
    z-index: 9999;
    flex-direction: row;
    align-items: center;
    padding: 8px 16px;
    gap: 12px;

    position: fixed;// 
    bottom: 27px; /* Espaçamento no fundo */
    right: 27px; /* Espaçamento na margem direita */
    cursor: pointer;
    width: 172px;
    height: 56px;

    /* Brand / Blue Base */
    background: ${props => props.config?.colors?.BRAND.BASE};
    border-radius: 360px;

    @media screen and (max-width: 786px) {
        display: none;
    }
`;



const SupportMenuMobileContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 8px 12px;
    gap: 12px;

    position: fixed; 
    bottom: 42px;
    right: 32px; 
    cursor: pointer;
    width: ${props => props?.showSupport ? "140px": "56px"};  
    height: 56px;

    /* Brand / Blue Base */
    background: ${props => props.config?.colors?.BRAND.BASE};
    border-radius: 360px;
    z-index: 999;

    @media screen and (min-width: 769px) {
        display: none;
    }
`;




const SupportItemOptionsRow = styled.div`
    display: flex;
    align-items: center;
    width: 368px;
    height: 48px;
    justify-content: space-between;
    padding: 12px 8px 12px 8px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
`;




const SupportOptionsContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    padding: 0px;
    width: 328px;
    
`;


const SupportTitleContainer = styled.div`
    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 16px 8px;
    gap: 84px;
    border-radius: 8px 8px 0 0;

    width: 368px;

    /* Brand / Blue Base */
    background: ${props => props.config?.colors?.BRAND.BASE};

    /* Inside auto layout */
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;

    @media screen and (max-width: 786px) {
        width: 320px;
    };
`;



const SupportContainer = styled.div`
  display: flex;
  z-index: 9999;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;

  position: fixed;
  bottom: 100px; /* Espaçamento no fundo */
  right: 32px; /* Espaçamento na margem direita */
  width: 368px;

  /* White / 100 */
  background: #FFFFFF;
  border-radius: 8px;

  @media screen and (max-width: 786px) {
    bottom: 170px;
    position: fixed;
    width: 320px;
  }
`;



const GridContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, auto);
    grid-row-gap: 20px;
    grid-column-gap: 100px;
    margin-top: 20px;
    
    @media screen and (max-width: 786px) {
        grid-template-columns: 1fr; /* Altera para uma única coluna */
        justify-content: center;
        align-items: center;
        margin-top: 0px;
        margin-bottom: 50px;
    }
    
    & > :nth-of-type(2) {
        margin-bottom: 10px; /* Adicione a margem para a segunda coluna */
    }
`;

const GridItem = styled.div`
    padding: 10px;
    @media screen and (max-width: 786px) {
        justify-content: center;
        align-items: center;
    }
 `;


const LinksItemFooterContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    @media screen and (max-width: 786px) {
        justify-content: center;
        align-items: center;
    }
 `;


const LinksOptionsItemFooterContainer = styled.div`
    /* Auto layout */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 10px;
    font-style: normal !important;

    width: fit-content;
    height: fit-content;

    opacity: 0.8;

    flex: none;
    flex-grow: 0;

    @media screen and (max-width: 786px) {
        justify-content: center;
        align-items: center;
    }
`;

const FooterContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
    height: 100%;
    background-color:  ${props => props.config?.colors?.BRAND.DARK};
    padding: 40px 0px 160px 0px;
    
    @media screen and (max-width: 768px) {
        flex-direction: column-reverse;
        width: 100%;
        height: 100%;
        padding: 40px 0px 180px 0px;
    }
`;

const LogoFooterContentContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 768px) {
    justify-content: center;
    align-items: center;
    margin: 30px;

    img {
      width: 89px;
      height: 89px;
      border-radius: 300px;
      object-fit: cover;
    }
  }

  @media screen and (min-width: 769px) {
    display: none;
  }
`;

const LogoFooterContainer = styled.div`
  position: absolute;
  display: flex;
  height: 100%;
  left: calc(((50vw - 470px) / 2) - 45px);
  justify-content: center;
  align-content: center;
  
  @media screen and (max-width: ${BREAKPOINTS.TABLET_LANDSCAPE}px) {
    display: none;
  }

  img {
    width: 90px;
    height: 90px;
    left: 151px;
    border-radius: 300px;
    object-fit: cover;
    margin-top: calc(100vh * 0.35);
  }
`;

const AllFootterContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media screen and (max-width: 786px) {
    justify-content: center;
    align-items: center;
  }

  .copyrights-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;

    span {
      color: ${props => props.config?.colors?.WHITE.W};
    }
  }
`;




const SponsorsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 16px;
  width: 940px;
  max-width: calc(100vw - 32px);
  top: 20px;
`;

const SponsorsContentContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: ${props => props?.twoRows ? "flex-start" : "center"};
  padding: 32px 0px 32px 0px; 
  gap:  ${props => props?.gap ? props?.gap : "60.56px"};
  opacity: ${props => props?.opacity ? props?.opacity : 1};
  width: 100%;
  max-width: ${props => props?.width ? props?.width : "940px"}; 
  /* White / 32 */
  border-bottom: ${props => props?.hasBorderBottom === false ? "none" : "1px solid rgba(255, 255, 255, 0.32)"};
  flex-wrap: ${props => props?.twoRows ? "wrap" : "nowrap" };
  
  @media screen and (max-width: 768px) {
    gap: ${props => props?.twoRows ? "50px" : "30px"};
    justify-content: center;
    align-items: center;
    padding: 32px 0px 32px 0px;
    margin: 0 16px;
  }
  
  @media screen and (max-width: 374px) {
    flex-wrap: wrap;
  }

  a {
    flex-grow: 0;
    object-fit: cover;

    img{
      height: 41px;
    }
  }
`;

const StyledTxt = styled(Txt)`
  margin: 0 auto;
  width: 243px;
  height: 20px;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #FFFFFF; 
`;
const StyledX = styled(X)`
  margin: 0 auto;
  width: 16px;
  height: 16px;
  cursor: pointer;
`;

const SupportItemTextContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 9px;
`;

const SupportItemIconContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px;
  @media screen and (max-width: 786px) {
    padding: 50px;
  };
`;

function SupportModalOptionsContainer(props) {
  return (
    <SupportContainer>
      <SupportTitleContainer config={props.config}>
          <StyledTxt typo="subtitle-lg">segunda a sexta, das 8h às 18h</StyledTxt>
          <StyledX onClick={() => props.setShowSupport(false)} color="white" />
      </SupportTitleContainer>
      <SupportOptionsContainer>
        <Link href="https://api.whatsapp.com/send?phone=553140002161" target="_blank" style={{ cursor: 'pointer' }} >
          <SupportItemOptionsRow>
              <SupportItemTextContainer>
                <FaWhatsapp color={props.config?.colors?.BRAND.DARK} size={32} />
                <Txt color={props.config?.colors?.BRAND.DARK} typo="paragraph-md" >Whatsapp</Txt>
              </SupportItemTextContainer>
              <SupportItemIconContainer>
                  <FaAngleRight color={props.config?.colors?.BRAND.BASE} size={16} />
              </SupportItemIconContainer>
          </SupportItemOptionsRow>
        </Link>

        <Link to={props.routes.caseNew.path} target="_blank" style={{ cursor: 'pointer' }} >
          <SupportItemOptionsRow>
            <SupportItemTextContainer>
              <FaRegEdit color={props.config?.colors?.BRAND.DARK} size={32} />
              <Txt color={props.config?.colors?.BRAND.DARK} typo="paragraph-md" >Abrir Chamado</Txt>
            </SupportItemTextContainer>
            <SupportItemIconContainer>
                <FaAngleRight color={props.config?.colors?.BRAND.BASE} size={16} />
            </SupportItemIconContainer>
          </SupportItemOptionsRow>
        </Link>
        <Link href="mailto:socio5estrelas@cruzeiro.com.br">
          <SupportItemOptionsRow>
            <SupportItemTextContainer>
              <FaRegEnvelope color={props.config?.colors?.BRAND.DARK} size={32} />
              <Txt color={props.config?.colors?.BRAND.DARK} typo="paragraph-md" >socio5estrelas@cruzeiro.com.br</Txt>
            </SupportItemTextContainer>
          </SupportItemOptionsRow>
        </Link>
        <Link href="tel:553140002161">
          <SupportItemOptionsRow>
            <SupportItemTextContainer>
              <LuPhone color={props.config?.colors?.BRAND.DARK} size={32} />
              <Txt color={props.config?.colors?.BRAND.DARK} typo="paragraph-md" >+55 31 4000-2161</Txt>
            </SupportItemTextContainer>
          </SupportItemOptionsRow>
        </Link>
      </SupportOptionsContainer>
    </SupportContainer>
  )
}

function SupportMenuBottom(props) {
    return (
        <SupportMenuContainer config={props.config} onClick={() => props?.setShowSupport(n => !n)}>
            <div style={{ padding: "8px", gap: "8px" }}>
                <SlEarphonesAlt width={32} height={32} color="white" style={{ gap: "8px" }} />
            </div>
            <Txt color="white" typo="subtitle-lg">Atendimento</Txt>
        </SupportMenuContainer>
    )
}

function SupportMenuMobileBottom(props) {
    return (
        <SupportMenuMobileContainer config={props.config} onClick={() => props?.setShowSupport(n => !n)} showSupport={props?.showSupport} >
            <div style={{ padding: "8px", gap: "8px" }}>
                <SlEarphonesAlt width={24} height={24} color="white" />
            </div>
            {props?.showSupport && <Txt color="white" typo="subtitle-lg">Atendimento</Txt>}
        </SupportMenuMobileContainer>
    )
}

function LinksFooter({ title, items }) {
  return (
    <GridItem >
          <LinksItemFooterContainer>
            <Txt color="white" typo="heading-footer-lg" style={{ fontStyle: "normal", fontWeight: "normal" }}>{title}</Txt>
            <LinksOptionsItemFooterContainer>
              {items.map((options, index) => (
                <a key={index} href={options?.url} target={'_blank'} style={{ cursor: 'pointer' }} rel="noreferrer"  >
                  <Txt color="white" typo="caption-lg-footer" style={{ lineHeight: "18.2px", letterSpacing: "normal", fontStyle: "normal"}}>{options?.label}</Txt>
                </a>
              ))}
            </LinksOptionsItemFooterContainer>
          </LinksItemFooterContainer>
    </GridItem>
  );
}

















